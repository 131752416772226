


























































































































































































































































import { defineComponent, ref, computed, Ref, ComputedRef } from '@vue/composition-api';
import { NotificationResponse, useMessages } from '@/admin/message';
import officialUsers from '@/composition/officialUser';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import FcCheckFilter from '@/components/FcCheckFilter.vue';
import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Messages',
  components: {
    FcCheckFilter,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('messages'));

    const messages = useMessages();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();

    // localStorageの確認
    const storageIds: Ref<string[]> = ref([]);
    for (let i = 0, length = window.localStorage.length; i < length; ++i) {
      const key = localStorage.key(i) || '';
      if (key.includes('notificationId:')) {
        const notificationId = key.replace('notificationId:', '');
        storageIds.value.push(notificationId);
      }
    }
    const notificationMessage = storageIds.value.length ? '編集中のデータがあります' : '';
    if (notificationMessage) notification.warning(notificationMessage, { timeout: 10000, icon: 'mdi-pencil' });

    const headers = [
      { text: 'ステータス', value: 'notificationReservationStatus' },
      { text: '送信者', value: 'sendingOfficialUserName' },
      { text: '作成日', value: 'createDate.dateTime' },
      { text: 'タイトル', value: 'title', width: '400px' },
      { text: 'メール送信', value: 'sendEmail' },
      { text: '通知対象', value: 'target' },
      { text: '通知日時', value: 'releaseDate.dateTime' },
      { text: '', value: 'actions', width: '250px', sortable: false, align: 'end' },
    ];

    // フィルター
    // ステータス
    const selectedTypeValues: Ref<string[]> = ref([]);
    const types = [
      { text: '下書き', value: 'draft' },
      { text: '通知予約', value: 'unreleased' },
      { text: '通知処理中', value: 'sending' },
      { text: '通知済', value: 'released' },
      { text: '通知終了', value: 'end' },
      { text: '通知エラー', value: 'failed' },
    ];

    // 送信者
    const selectedSenderValues: Ref<string[]> = ref([]);
    const senders: ComputedRef<Option[]> = computed(() =>
      // fetch中は空配列にし、終了していたら関数を叩くように変更
      officialUsers.fetchLoading ? [] : officialUsers.getOfficialUserOptions(true, '表示しない', true)
    );

    // フリーワード検索
    const searchWord = ref('');

    const displayMessages = computed(() =>
      messages.items
        .filter(
          (item) =>
            !selectedTypeValues.value.length ||
            (item.notificationReservationStatus &&
              selectedTypeValues.value.includes(item.notificationReservationStatus))
        )
        .filter((item) => {
          if (!selectedSenderValues.value.length) return true;
          else return !!selectedSenderValues.value.includes(item.sendingOfficialUserId);
        })
        .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value))
    );

    // 即時通知
    const isActiveDialog = ref(false);
    const isSending = ref(false);
    const errorString = ref('');
    const targetUserCount: Ref<number | string> = ref(0); // 通知対象として指定した人数（targetUserCount + invalidUsernames.length）
    const failedUsernames: Ref<string[]> = ref([]); // 通知対象として指定したユーザーのうち、通知が作成できなかったユーザー（invalidUsernames + failedUsernames）
    const isFailedEmail = ref(false);
    const targetReservationId = ref('');

    const openDialog = (notificationReservationId: string) => {
      targetReservationId.value = notificationReservationId;
      isActiveDialog.value = true;
    };

    const closeDialog = () => {
      targetReservationId.value = '';
      isActiveDialog.value = false;
      errorString.value = '';
      targetUserCount.value = 0;
      failedUsernames.value = [];
    };

    const notifyMessage = async () => {
      const notificationReservationId = targetReservationId.value;
      if (!notificationReservationId) return;

      const result: Ref<NotificationResponse | null> = ref(null);

      isSending.value = true;
      messages.setSendingStatus(notificationReservationId);
      try {
        result.value = await messages.notifyMessage(notificationReservationId);
      } catch (error) {
        errorString.value =
          error instanceof Error
            ? `エラーが発生しました。お手数ですが、再通知等の操作は行わずお問い合わせください。<br>エラーメッセージ：${error.message}`
            : (error as string)
            ? `エラーが発生しました。お手数ですが、再通知等の操作は行わずお問い合わせください。<br>エラーメッセージ：${error as string}`
            : '通知処理中です。<br>しばらく待ってステータスを確認してください。<br>ページをリロードいただきステータスが「通知済」に切り替われば通知完了です。';
      } finally {
        if (result.value) {
          isFailedEmail.value = result.value.isFailedEmail;
          // 削除済プランを選択した場合など通知対象が0の場合もある
          targetUserCount.value = result.value.targetUserCount + result.value.invalidUsernames.length || '0';
          failedUsernames.value = [...result.value.failedUsernames, ...result.value.invalidUsernames];
        }
        // ステータスと通知日時の更新
        messages.getMessages();
        messages.removeStorage(notificationReservationId);
        isSending.value = false;
      }
    };

    // 複製
    const copyMessage = async (notificationReservationId: string) => {
      try {
        await messages.copyMessage(notificationReservationId);
        await messages.getMessages();
        notification.notify('通知を複製しました。');
      } catch (error) {
        notification.error(error);
      }
    };

    // 削除
    const deleteMessage = async (notificationReservationId: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      try {
        await messages.deleteMessage(notificationReservationId);
        await messages.getMessages();
        messages.removeStorage(notificationReservationId);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
    };

    // もっと読み込む
    const isNextItemLoading = ref(false);
    const getNextMessages = async () => {
      isNextItemLoading.value = true;
      try {
        await messages.getNextMessages(messages.nextToken);
      } catch (error) {
        notification.error(error);
      } finally {
        isNextItemLoading.value = false;
      }
    };

    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    return {
      pageTitle: 'メッセージBOX',
      myRoleSettings,
      hostingUrl: core.hostingUrl,
      messages,
      headers,
      selectedTypeValues,
      types,
      selectedSenderValues,
      senders,
      searchWord,
      displayMessages,
      isActiveDialog,
      isSending,
      errorString,
      isFailedEmail,
      targetUserCount,
      failedUsernames,
      openDialog,
      closeDialog,
      notifyMessage,
      copyMessage,
      deleteMessage,
      tableHeight,
      isNextItemLoading,
      getNextMessages,
      storageIds,
    };
  },
});
